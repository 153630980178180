import React, { FC } from 'react';
import { ThemeProvider } from '../modules';
import theme from './theme.json';

type Props = {
  children: React.ReactNode;
}

const ThemeProviderContainer: FC<Props> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProviderContainer;
