import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { LoadingPage } from '../LoadingPage'
import { ErrorPage } from "../ErrorPage"
import { PersonalDocumentSlider } from './slider'
import { Button } from '../../components';
import * as S from './styles';
import { handleDeviceScanRequestHelper } from 'src/utils/helpers/handleDeviceScan'
import { RetryDeviceScanDialog } from '../Login/RetryDeviceScanDialog'

export const PersonalDocumentConfirmation = (props) => {

    const { clientThemeSettings, signData, setSignerStep } = props
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const initialError = {
        "has_error": false,
        "status": 0
    }

    const [openRetryDeviceScanDialog, setOpenRetryDeviceScanDialog] = useState(false)
    
    const onSubmit = () => {
        const data = {
            "event_date": new Date().toISOString(),
            "step": "personal_document"
        }
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }
        setLoading(true)
        axios.patch('/signer', data, config)

            .then(response => {
                if (response.data.next_step === 'device_scan') {
                    handleDeviceScanRequestHelper("Personal Document Confirmation", "1", signData, setSignerStep, setError, setLoading, null, null, setOpenRetryDeviceScanDialog)
                } else {
                    setSignerStep(response.data.next_step)
                }
            })
            .catch((error) => {
                if ([401, 403].includes((error.response || {}).status)) {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                } else {
                    setError({
                        "has_error": true,
                        "status": 500
                    })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    return (
            <S.PersonalDocumentConfirmationContainer>
                <p className="description-level4 gray no-border-container-fullwidth" style={{ textAlign: "justify", width: "100%" }}>
                    Confirme que você é o titular do documento abaixo, clicando no botão "Confirmar".
                </p>
                <PersonalDocumentSlider
                    documents={signData.personal_documents}
                    clientThemeSettings={clientThemeSettings}
                />
                <div className="no-border-container-center" style={{marginTop: 0}}>
                    <Button style = {{width:120}}
                        onClick={onSubmit}
                        clientThemeSettings={clientThemeSettings}
                    >
                        CONFIRMAR
                    </Button>
                </div>
                {error.has_error &&
                    <ErrorPage
                        error={error}
                        redirectToLogin={() => setSignerStep("login")}
                        resetError={() => setError(initialError)}
                        clientThemeSettings={clientThemeSettings}
                    />}
                {openRetryDeviceScanDialog &&
                <RetryDeviceScanDialog
                    open={openRetryDeviceScanDialog}
                    retryDeviceScan={() => handleDeviceScanRequestHelper("Personal Document Confirmation", "1", signData, setSignerStep, setError, setLoading, null, null, setOpenRetryDeviceScanDialog)}
                    clientThemeSettings={clientThemeSettings}
                />}
            </S.PersonalDocumentConfirmationContainer>
    )
}