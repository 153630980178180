import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Wallpaper } from '../../components/Wallpaper'
import FormField from '../../components/FormField'
import { isNaturalDocumentNumber, cpfMask, isDate, dateMask, formatToYYYYMMDD } from '../../utils/utils'
import useInput from '../../utils/hooks/useInput'
import { Footer } from '../../components/Footer'
import { NotFoundDialog } from './NotFoundDialog'
import { LoadingPage } from '../LoadingPage'
import { v4 as uuidv4 } from 'uuid'
import { LocationDialog } from './LocationDialog'
import { Logo } from '../../components'
import { Button } from './styles'
import { useCookies } from 'react-cookie'
import { handleDeviceScanBatchRequestHelper } from 'src/utils/helpers/handleDeviceScanBatch'
import { RetryDeviceScanDialog } from './RetryDeviceScanDialog'

export const LoginBatch = (props) => {
    const { clientThemeSettings, setSignBatchData, onSendOTPCode, setSignerBatchStep, signBatchData } = props
    const { signerBatchHash } = useParams()
    const [cookies, setCookie] = useCookies([`qisign_${signerBatchHash}`])
    const [hasMadeRequest, setHasMadeRequest] = useState(false)
    const [termsCheckboxChecked, setTermsCheckboxChecked] = useState(false)
    const handleTermsCheckboxChange = (event) => {
        setTermsCheckboxChecked(event.target.checked)
    }
    let fields = {
        document_number: {
            id: "document_number",
            name: "Número do CPF",
            errorMessage: "Número de CPF inválido.",
        },
        birthdate: {
            id: "birthdate",
            name: "Data de Nascimento (DD/MM/AAAA)",
            errorMessage: "Data de nascimento inválida."
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (documentNumberIsValid && birthdateIsValid && termsCheckboxChecked) {
                handleAskForLocation()
            }
        }
    }

    const {
        value: documentNumberValue,
        isValid: documentNumberIsValid,
        hasError: documentNumberHasError,
        valueChangeHandler: documentNumberValueChangeHandler,
        inputBlurHandler: documentNumberInputBlurHandler,
        reset: documentNumberReset
    } = useInput(isNaturalDocumentNumber)

    const {
        value: birthdateValue,
        isValid: birthdateIsValid,
        hasError: birthdateHasError,
        valueChangeHandler: birthdateValueChangeHandler,
        inputBlurHandler: birthdateInputBlurHandler,
        reset: birthdateReset
    } = useInput(isDate)

    const [openNotFoundDialog, setOpenNotFoundDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const [latitude, setLatitude] = useState(undefined)
    const [longitude, setLongitude] = useState(undefined)
    const [openLocationDialog, setOpenLocationDialog] = useState(false)
    const [geolocationRequestCount, setGeolocationRequestCount] = useState(0)
    const [deviceScanHasMadeRequest, setDeviceScanHasMadeRequest] = useState(false)

    const [openRetryDeviceScanDialog, setOpenRetryDeviceScanDialog] = useState(false)

    const doRequest = useCallback(() => {
        setGeolocationRequestCount(geolocationRequestCount + 1)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude)
                    setLongitude(position.coords.longitude)
                }
            )
        }
    }, [geolocationRequestCount])

    const handleSendOTPCode = useCallback(
        async (responseData) => {
            if (responseData.use_authentication_code && !cookies[`qisign_${signerBatchHash}`]) {
                onSendOTPCode(responseData.signer_token)
            }
        },
        [onSendOTPCode, signerBatchHash, cookies]
    )

    const onSubmit = useCallback((documentNumber, birthdate, alreadyFormatted) => {
        if (latitude === undefined & longitude === undefined && signBatchData.signature_settings?.geo_location_required === true) {
            doRequest()
            return
        }

        if (hasMadeRequest) {
            return
        }

        const payload = {
            "document_number": cpfMask(documentNumber),
            "birthdate": formatToYYYYMMDD(birthdate),
        }

        if (alreadyFormatted) {
            payload["document_number"] = documentNumber
            payload["birthdate"] = birthdate
        }


        if (latitude !== undefined & longitude !== undefined) {
            payload["gps_data"] = {
                "coordinates": [
                    longitude,
                    latitude
                ]
            }
        }

        setLoading(true)
        setHasMadeRequest(true)
        axios.post('/signer_batch/hash/' + signerBatchHash, payload)
            .then(async (response) => {
                const session_id = uuidv4()
                setSignBatchData({ ...signBatchData, ...response.data, session_id })
                await handleSendOTPCode(response.data)
                setOpenLocationDialog(false)
                documentNumberReset()
                birthdateReset()
                setCookie(`qisign_${signerBatchHash}`, payload, { path: `/sb/${signerBatchHash}`, maxAge: 60 * 60 })
                
                if (response.data.next_step === 'device_scan') {
                    handleDeviceScanBatchRequestHelper("Login", "1", { ...signBatchData, ...response.data, session_id }, setSignerBatchStep, null, setLoading, deviceScanHasMadeRequest, setDeviceScanHasMadeRequest, setOpenRetryDeviceScanDialog)
                } else {
                    setSignerBatchStep(response.data.next_step)
                }

            }).catch((error) => {
                if ([401, 403].includes((error.response || {}).status)) {
                    setOpenLocationDialog(false)
                    setOpenNotFoundDialog(true)
                    setHasMadeRequest(false)
                }
                setCookie(`qisign_${signerBatchHash}`, '', { path: `/sb/${signerBatchHash}`, maxAge: 0 })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [setCookie, setSignBatchData, handleSendOTPCode, birthdateReset, documentNumberReset, latitude, longitude, signerBatchHash, signBatchData, setSignerBatchStep, doRequest, hasMadeRequest, deviceScanHasMadeRequest])

    useEffect(() => {
        if (cookies[`qisign_${signerBatchHash}`] && isNaturalDocumentNumber(cookies[`qisign_${signerBatchHash}`].document_number) && !hasMadeRequest) {
            onSubmit(cookies[`qisign_${signerBatchHash}`].document_number, cookies[`qisign_${signerBatchHash}`].birthdate, true)
        }
    }, [cookies, signerBatchHash, onSubmit, hasMadeRequest])

    const handleAskForLocation = () => {
        setOpenLocationDialog(true)
        doRequest()
    }

    const handleLocationDialogClose = () => {
        setOpenLocationDialog(false)
    }

    const handleClick = () => {
        if (signBatchData.signature_settings?.geo_location_required === false) {
            onSubmit(documentNumberValue, birthdateValue, false)
            return
        }

        if (documentNumberIsValid && birthdateIsValid) {
            handleAskForLocation()
        }
    }

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    return (
        <Wallpaper
            backgroundColor={clientThemeSettings.background_color}
        >
            <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />
                <p className="description-level4 gray no-border-container-fullwidth" style={{ textAlign: "justify" }}>
                    Para iniciar o processo de assinatura em lote, preencha os campos abaixo:
                </p>
                <div className="no-border-container-fullwidth">
                    <FormField
                        labelValue={fields.document_number.name}
                        onChange={documentNumberValueChangeHandler}
                        onBlur={documentNumberInputBlurHandler}
                        hasError={documentNumberHasError}
                        fieldValue={cpfMask(documentNumberValue)}
                        errorMessage={fields.document_number.errorMessage}
                        onKeyDown={handleKeyDown}
                        type="numeric"
                    />
                    <FormField
                        labelValue={fields.birthdate.name}
                        onChange={birthdateValueChangeHandler}
                        onBlur={birthdateInputBlurHandler}
                        hasError={birthdateHasError}
                        fieldValue={dateMask(birthdateValue)}
                        errorMessage={fields.birthdate.errorMessage}
                        onKeyDown={handleKeyDown}
                        type="numeric"
                    />
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="termsCheckbox"
                            onChange={handleTermsCheckboxChange}
                            checked={termsCheckboxChecked}
                            onKeyDown={handleKeyDown}
                            style={{ transform: 'scale(1.5)', marginRight: '5px' }}

                        />
                        <label htmlFor="termsCheckbox" style={{ marginLeft: '8px', textAlign: 'justify' }}>
                            Concordo com os <a href="https://sign.qitech.com.br/docs/termos_de_uso.pdf" target="_blank" rel="noreferrer">Termos de Uso</a> e <a href="https://sign.qitech.com.br/docs/politica_de_privacidade.pdf" target="_blank" rel="noreferrer">Política de Privacidade</a>.
                        </label>
                    </div>
                    <Button
                        onClick={handleClick}
                        type='primary'
                        disabled={!documentNumberIsValid || !birthdateIsValid || !termsCheckboxChecked}
                        clientThemeSettings={clientThemeSettings}
                    >
                        INICIAR
                    </Button>
                </div>
                <Footer />
            </div>
            {openLocationDialog &&
                <LocationDialog
                    open={openLocationDialog}
                    onClose={() => handleLocationDialogClose()}
                    onSubmit={() => onSubmit(documentNumberValue, birthdateValue, false)}
                    geolocationRequestCount={geolocationRequestCount}
                    clientThemeSettings={clientThemeSettings}
                />}
            {openNotFoundDialog &&
                <NotFoundDialog
                    open={openNotFoundDialog}
                    onClose={() => setOpenNotFoundDialog(false)}
                    clientThemeSettings={clientThemeSettings}
                />}
            {openRetryDeviceScanDialog &&
                <RetryDeviceScanDialog
                    open={openRetryDeviceScanDialog}
                    retryDeviceScan={() => handleDeviceScanBatchRequestHelper("Login", "1", signBatchData, setSignerBatchStep, null, setLoading, deviceScanHasMadeRequest, setDeviceScanHasMadeRequest, setOpenRetryDeviceScanDialog)}
                    clientThemeSettings={clientThemeSettings}
                />}
        </Wallpaper>
    )
}