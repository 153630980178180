import React from "react"
import "../assets/styles/index.css"

export const Wallpaper = (props) => {

    const { backgroundColor } = props

    return (
        <div className="page-container" style={{ backgroundColor: backgroundColor }}>
            {props.children}
        </div>
    )
}
