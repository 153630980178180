import React from "react"
import { Wallpaper } from '../../components/Wallpaper'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Footer } from '../../components/Footer'

export const ExpiredPage = (props) => {

    const { clientThemeSettings } = props

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]}>
            <div className="base-container base-container-width base-container-padding" style={{ display: "flex", backgroundColor: "rgb(245, 245, 242)", minHeight: "40%", alignItems: "center", textAlign: "center" }}>
                <div style={{ margin: "auto" }} className="gray">
                    <CancelOutlinedIcon className="red-color" style={{ width: "50px", height: "50px", margin: "20px" }}/>
                    <h1 className="title-level2" style={{ margin: "auto" }}>Processo expirado</h1>
                </div>
                <Footer showLink={true} />
            </div>
        </Wallpaper>
    )
}