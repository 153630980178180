import { getLuminance } from 'polished';

import theme from '../../theme/theme.json';

export const getColorContrast = (color: string) => {
  if (!color) return '';
  
  return getLuminance(color) > 0.55 ? 
    theme.colors.colorBlack : 
    theme.colors.colorNeutral
}