import React from "react"
import { Dialog } from "@mui/material"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { Button } from '../../../components';
import { getColorContrast } from '../../../utils/helpers';

export const LocationDialog = (props) => {

    const { open, onClose, onSubmit, geolocationRequestCount, clientThemeSettings } = props

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={onClose}
            keepMounted={false}
        >
            <div className="no-border-container-center background-gray" style={{ width: "100%", display: "flex", flexDirection: "row", padding: "5px", margin: "0", justifyContent: "space-evenly", backgroundColor: clientThemeSettings.background_color }}>
                <div style={{ display: "flex", flexDirection: "column", margin: "0px 53px" }}>
                    <h1 className="subtitle-level4 gray" style={{ color: getColorContrast(clientThemeSettings.background_color) }}>
                        ATENÇÃO
                    </h1>
                </div>
                <IconButton
                    onClick={onClose}
                    style={{ position: "absolute" }}
                >
                    <CloseIcon style={{ position: "absolute", right: "7px", color: getColorContrast(clientThemeSettings.background_color) }} />
                </IconButton>
            </div>
            <div className="no-border-container-margin" style={{ textAlign: "center" }}>
                <span style={{ margin: "5px auto", fontWeight: "bold" }}>
                    Você deve aceitar compartilhar sua geolocalização para continuar o processo.
                </span>
                {(geolocationRequestCount < 2) ?
                    <span style={{ margin: "5px auto" }}>
                        Caso tenha negado, vá para as configurações de navegador e aceite compartilhá-las.
                    </span>
                    :
                    <span style={{ margin: "5px auto" }}>
                        Identificamos que o acesso à geolocalização está negado. Entre nas configurações do navegador ou do celular para autorizar a captura da geolocalização.
                    </span>}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 2rem 1rem' }}>
                <Button
                    clientThemeSettings={clientThemeSettings}
                    onClick={onSubmit}
                >
                    CONTINUAR
                </Button>
            </div>
        </Dialog>
    )
}