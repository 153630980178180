import React, { FC } from 'react';
import { Text } from './styles';

type Props = {
  children: React.ReactNode;
  clientThemeSettings?: ClientThemeSettings;
}

const Typography: FC<Props> = ({ 
    clientThemeSettings = {} as ClientThemeSettings, 
    children, 
    ...rest 
}) => (
  <Text {...rest} $clientThemeSettings={clientThemeSettings}>
    {children}
  </Text>
);

export default Typography;