import './App.css'
import { MainRouter } from './container/MainRouter'
import axios from "axios"
import { datadogRum } from '@datadog/browser-rum'
import { ThemeProvider } from './theme'
import { CookiesProvider } from 'react-cookie'

function App() {

  axios.defaults.baseURL = process.env.REACT_APP_SIGN_API_URL

  if (process.env.REACT_APP_ENV !== 'local') {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'qisign',
      env: process.env.REACT_APP_ENV,
      version: '1.0.0',
      sampleRate: 100,
      sessionReplaySampleRate: 10,
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow'
    })

    datadogRum.startSessionReplayRecording()
  }

  return (
    <CookiesProvider>
      <ThemeProvider>
        <MainRouter />
      </ThemeProvider>
    </CookiesProvider>
  )
}

export default App
