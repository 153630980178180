import { useEffect, useState } from 'react'

import axios from 'axios'

import RGIcon from '../../assets/images/rg-icon.png'
import CNHIcon from '../../assets/images/cnh-icon.png'

import { Box } from '@mui/material'
import { Logo } from '../../components';
import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import { Footer } from "../../components/Footer"
import { getColorContrast } from '../../utils/helpers'
import { Wallpaper } from '../../components/Wallpaper'
import { RetryDeviceScanDialog } from '../Login/RetryDeviceScanDialog'
import { PersonalDocumentConfirmation } from './personalDocumentConfirmation'
import { handleDeviceScanRequestHelper } from 'src/utils/helpers/handleDeviceScan'
import { CollectImageTitle, DocumentImg, DocumentImgCnh, DocumentText } from './styles'

export const PersonalDocument = (props) => {

    const { clientThemeSettings, signData, setSignerStep } = props
    const initialError = {"has_error": false, "status": 0}

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)
    const [openRetryDeviceScanDialog, setOpenRetryDeviceScanDialog] = useState(false)

    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    var htmlComponent = document.getElementById('root')

    var webOCR = new window.QiTechWebOCR.WebOCR(
        htmlComponent,
        signData.ocr_token,
        signData.session_id
    )
    .setThemeConfiguration(
        {
            "companyLogo": clientThemeSettings["logo_url"],
            "backgroundColor": clientThemeSettings["background_color"],
            "fontColor": getColorContrast(clientThemeSettings["button_color"]),
            "buttonColor": clientThemeSettings["button_color"],
        }
    )
    .setShowInstructionScreen(true)
    .setShowSuccessScreen(false)

    process.env.REACT_APP_ENV === 'production' ? webOCR.build() : webOCR.setSandboxEnvironment().build()

    function initOCR(document_type) {
        webOCR.initialize(document_type)
        .then((ocr_key) => {
            submitOCRKeys(ocr_key)
        })
    }

    const submitOCRKeys = (ocrKeys) => {

        if (hasMadeRequest) {
            return  
        }

        const firstOcrKey = ocrKeys[0]
        const secondOcrKey = ocrKeys[1]

        const ocrFrontKey = firstOcrKey['template'].split('_')[1]  === 'front' ? firstOcrKey['ocr_key'] : secondOcrKey['ocr_key']
        const ocrBackKey = firstOcrKey['template'].split('_')[1]  === 'back' ? firstOcrKey['ocr_key'] : secondOcrKey['ocr_key']

        const payload = {
            "ocr_front_key": ocrFrontKey,
            "ocr_back_key": ocrBackKey,
            "step": "personal_document",
            "event_date": new Date().toISOString()
        }
    
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }
    
        setLoading(true)
        setHasMadeRequest(true)

        axios.patch('/signer', payload, config)
        .then(response => {
            if (response.data.next_step === 'device_scan') {
                handleDeviceScanRequestHelper("Personal Document", "1", signData, setSignerStep, setError, setLoading, null, null, setOpenRetryDeviceScanDialog)
            } else {
                setSignerStep(response.data.next_step)
            }
        })
        .catch((error) => {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": error.response.status
                })
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                })
            }

            setHasMadeRequest(false)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]} style={{ padding: '10px' }}>
            <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />
                <Box>
                    {(!signData.collect_personal_document) && signData.personal_documents ? (
                        <PersonalDocumentConfirmation clientThemeSettings={clientThemeSettings} signData={signData} setSignerStep={setSignerStep} />
                    ) : (
                        <div>
                            <CollectImageTitle>
                                Agora vamos coletar imagens do seu documento. Escolha entre os tipos abaixo:
                            </CollectImageTitle>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                <div className='personalDocumentTypes' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }} onClick={() => initOCR("cnh")}>
                                    <DocumentText>Carteira de Habilitação (CNH)</DocumentText>
                                    <DocumentImgCnh src={CNHIcon} alt="CNH" />
                                </div>

                                <div className='personalDocumentTypes' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }} onClick={() => initOCR("rg")}>
                                    <DocumentText>Cédula de identidade (RG)</DocumentText>
                                    <DocumentImg src={RGIcon} alt="RG" />
                                </div>
                            </div>
                        </div>
                    )}
                </Box>
                <Footer />
            </div>
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />
            }
            {openRetryDeviceScanDialog &&
                <RetryDeviceScanDialog
                    open={openRetryDeviceScanDialog}
                    retryDeviceScan={() => handleDeviceScanRequestHelper("OCR", "1", signData, setSignerStep, setError, setLoading, null, null, setOpenRetryDeviceScanDialog)}
                    clientThemeSettings={clientThemeSettings}
                />
            }
        </Wallpaper>
    )
}