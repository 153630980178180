import React from "react"
import { Wallpaper } from '../../components/Wallpaper'
import { CircularProgress } from "@mui/material"

export const LoadingPage = (props) => {

    const { clientThemeSettings } = props


    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]}>
            <div className="base-container base-container-width base-container-padding" style={{ display:"flex", backgroundColor: "rgb(245, 245, 242)", minHeight: "40%", alignItems: "center"}}>
                <CircularProgress style={{margin:"auto"}}/>
            </div>
        </Wallpaper>
    )
}