import axios from 'axios'

export const handleDeviceScanBatchRequestHelper = async (eventType, eventId, signBatchData, setSignerBatchStep, setError, setLoading, deviceScanHasMadeRequest, setDeviceScanHasMadeRequest, setOpenRetryDeviceScanDialog) => {

    if (deviceScanHasMadeRequest !== null) {
        if (!signBatchData.signer_token || deviceScanHasMadeRequest) {
            return;
        }
    }

    if (!window.vPkg?.DeviceScan) {
        setOpenRetryDeviceScanDialog(true)
    }

    var deviceScan = process.env.REACT_APP_ENV === 'production' ? new window.vPkg.DeviceScan(signBatchData.device_token, signBatchData.session_id) : new window.vPkg.DeviceScan(signBatchData.device_token, signBatchData.session_id).setSandbox()

    async function callDeviceScan(eventType, eventId) {
        await deviceScan.info(eventType, eventId).then(() => {
            setOpenRetryDeviceScanDialog(false)
        }).catch(() => {
            setOpenRetryDeviceScanDialog(true)
            throw new Error("Failed Device Scan")
        })
    }

    try {
        await callDeviceScan(eventType, eventId)
    } catch (error) {
        return
    }

    const data = {
        "event_date": new Date().toISOString(),
        "step": "device_scan",
        "session_id": signBatchData.session_id
    };

    const config = {
        headers: {
            'Authorization': signBatchData.signer_token
        }
    };

    try {
        const response = await axios.patch('/signer_batch', data, config);
        setSignerBatchStep(response.data.next_step);
        if (setDeviceScanHasMadeRequest !== null) {
            setDeviceScanHasMadeRequest(true)
        }
    } catch (error) {
        if (setError !== null) {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": error.response.status
                });
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                });
            }
        }
    } finally {
        setLoading(false);
    }
};