import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import CustomError from './CustomError';

type Props = {
  children: JSX.Element | JSX.Element[];
  clientThemeSettings: ClientThemeSettings;
};

const ErrorBoundaryComponent: FC<Props> = ({ children, clientThemeSettings }) => {
  return (
    <ErrorBoundary fallbackRender={({ resetErrorBoundary }) => <CustomError resetErrorBoundary={resetErrorBoundary} clientThemeSettings={clientThemeSettings} />}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryComponent;
