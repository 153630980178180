import { FC, PropsWithChildren, useState } from 'react';
import { CircularProgress } from '@mui/material'
import {
  ButtonWrapper,
  LoadContainer,
} from './styles';

type Props = {
  type?: 'primary' | 'primaryGhost' | 'secondary' | 'secondaryGhost';
  clientThemeSettings?: ClientThemeSettings;
  disabled?: boolean;
  noStyleTextColor?: string;
  loading?: boolean;
  onClick(): Promise<void> | void;
};

const Touchable: FC<PropsWithChildren<Props>> = ({
  children,
  disabled,
  loading,
  type = 'primary',
  noStyleTextColor,
  clientThemeSettings = {} as ClientThemeSettings,
  onClick,
  ...rest
}) => {
  const [internalLoading, setInternalLoading] = useState(false);


  const handleOnPress = (): void => {
    if (loading || internalLoading) return;
    
    setTimeout(async (): Promise<void> => {
      try {
        setInternalLoading(true);
        await onClick();
      } catch (error) {
        throw error;
      } finally {
        setInternalLoading(false);
      }
    }, 100);
  };

  return (
    <>
      <ButtonWrapper
        disabled={!!disabled || loading || internalLoading}
        $isLoading={loading || internalLoading}
        type={type}
        $clientThemeSettings={clientThemeSettings}
        onClick={handleOnPress}
        {...rest}
      >
        {(loading || internalLoading) ? (
          <LoadContainer>
            <CircularProgress size={20} />
          </LoadContainer>
        ) : (
          <>
            {children}
          </>
        )}
      </ButtonWrapper>
    </>
  );
};

export default Touchable;
