import styled, { DefaultTheme } from 'styled-components';
import { ButtonBase } from '@mui/material';

import { getColorContrast } from '../../utils/helpers'

type ButtonType = {
  type: 'clientType' | 'primary' | 'primaryGhost' | 'secondary' | 'secondaryGhost';
  disabled: boolean;
  $clientThemeSettings: ClientThemeSettings;
  $isLoading: boolean;
};

const buttonStyle = (theme: DefaultTheme, customColor = '') => ({
  primary: {
    color: getColorContrast(customColor) || theme.colors.colorNeutral,
    backgroundColor: customColor || theme.colors.colorPrimary,
    border: '0',
    leftIconColor: getColorContrast(customColor) || theme.colors.colorNeutral,
    rightIconColor: getColorContrast(customColor) || theme.colors.colorNeutral,
  },
  primaryGhost: {
    color: customColor || theme.colors.colorPrimary,
    backgroundColor: getColorContrast(customColor) || theme.colors.colorNeutral,
    border: `1px solid ${customColor || theme.colors.colorPrimary}`,
    leftIconColor: customColor || theme.colors.colorPrimary,
    rightIconColor: customColor || theme.colors.colorPrimary,
  },
  secondary: {
    color:  getColorContrast(customColor) || theme.colors.colorNeutral,
    backgroundColor: customColor || theme.colors.colorSecondary,
    border: '0',
    leftIconColor: customColor || theme.colors.colorSecondary,
    rightIconColor: customColor || theme.colors.colorSecondary,
  },
  secondaryGhost: {
    color: customColor || theme.colors.colorSecondary,
    backgroundColor: getColorContrast(customColor) || theme.colors.colorNeutral,
    border: `1px solid ${customColor || theme.colors.colorSecondary}`,
    leftIconColor: customColor || theme.colors.colorSecondary,
    rightIconColor: customColor || theme.colors.colorSecondary,
  },
})

export const ButtonWrapper = styled(ButtonBase)<ButtonType>`
  width: 100%;
  min-width: 140px;
  height: 30px;
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  opacity: ${props => props.$isLoading ? 1 : props.disabled ? 0.4 : 1};
  font-weight: 700;
  text-transform: uppercase;
  cursor: ${props => props.disabled ? 'not-allowed' : "pointer"};
  ${(props) => {
    const getClientTheme = props?.$clientThemeSettings?.button_color || '';
    const defaultButtonStyle = buttonStyle(props.theme, getClientTheme)[props.type];
    
    return `
      color: ${defaultButtonStyle.color};
      background-color: ${defaultButtonStyle.backgroundColor};
      border: ${defaultButtonStyle.border};
      &:hover {
        background-color: ${defaultButtonStyle.backgroundColor}CC;
      }
    `
  }}
`;

export const LoadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;