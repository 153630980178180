import React, { useEffect } from "react"
import { Wallpaper } from '../../components/Wallpaper'
/* eslint-disable no-restricted-globals */

export const NotFoundPage = (props) => {

    const { clientThemeSettings } = props

    useEffect(() => {
        if (location.pathname === "/" && process.env.REACT_APP_ENV !== 'local') {
            window.location.href = 'https://qitech.com.br/sign/'
        }
    }, [])


    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]}>
            <div className="base-container base-container-width base-container-padding" style={{ display: "flex", backgroundColor: "rgb(245, 245, 242)", minHeight: "40%", alignItems: "center", textAlign: "center" }}>
                <h1 className="title-level2 gray" style={{ margin: "auto" }}>Página não encontrada</h1>
            </div>
        </Wallpaper>
    )
}