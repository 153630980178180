import { Dialog } from "@mui/material"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { Button } from '../../components';

export const ErrorPage = (props) => {

    const { error, redirectToLogin, resetError, errorMessage, clientThemeSettings } = props

    let buttonMessage = "TENTAR NOVAMENTE"
    if ([401, 403].includes(error.status)) {
        buttonMessage = "VOLTAR PARA O LOGIN"
    }

    const redirectHandler = () => {
        if ([401, 403].includes(error.status)) {
            redirectToLogin()
            resetError()
        } else {
            resetError()
        }
    }

    return (
        <Dialog
            maxWidth="xs"
            open={error.has_error}
            onClose={redirectHandler}
            keepMounted={false}
        >
            <div className="no-border-container-center background-gray" style={{ width: "100%", display: "flex", flexDirection: "row", padding: "5px", margin: "0", justifyContent: "space-evenly", backgroundColor: '#fd5b77' }}>
                <div style={{ display: "flex", flexDirection: "column", margin: "0px 53px" }}>
                    <h1 className="subtitle-level4 gray" style={{ color: '#fff' }}>
                        ERRO
                    </h1>
                </div>
                <IconButton
                    onClick={redirectHandler}
                    style={{ position: "absolute"}}
                >
                    <CloseIcon style={{ position: "absolute", color: '#fff', right: "7px" }} />
                </IconButton>
            </div>
            <div className="no-border-container-margin" style={{ textAlign: "center" }}>
                {errorMessage ? errorMessage : "Ocorreu um erro inesperado"}
            </div>
            <div className="no-border-container-center" style={{ margin: "0px 20px 20px" }}>
                <Button
                    clientThemeSettings={clientThemeSettings}
                    onClick={redirectHandler}
                >
                    {buttonMessage}
                </Button>
            </div>
        </Dialog>
    )
}

