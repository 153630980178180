import React, { useRef } from 'react'


export const OTPInput = (props) => {

    const { error, otpInput, setOTPInput } = props

    const inputRefs = useRef([])

    const handleInputChange = (event, index) => {
        let { id, value } = event.target
        value = value.toUpperCase()
        let nextIDNumber = 0
        let nextID = ""
        let nextValue = ""
        let inputType = event.nativeEvent.inputType

        if (inputType === "insertFromPaste") {
            const focusWhenPasted = Math.min(value.length - 1, 5)
            const newOtpInput = {
                otp1: value[0],
                otp2: value[1],
                otp3: value[2],
                otp4: value[3],
                otp5: value[4],
                otp6: value[5],
            }
            inputRefs.current[focusWhenPasted].focus()
            setOTPInput(newOtpInput)
        } else {
            if (value.length >= 1 && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus()
            }
            if ([0, 1].includes(value.split("").length)) {
                setOTPInput({ ...otpInput, [id]: value })
                return
            } else if (value.split("").length > 1) {
                nextIDNumber = Number(id.split("otp")[1]) + 1
                nextValue = value.split("")[1]
                for (let i = nextIDNumber; i < 7; i++) {
                    nextID = "otp" + String(i)
                    if (otpInput[nextID] === "") {
                        setOTPInput({ ...otpInput, [nextID]: nextValue })
                        return
                    }
                }
            }
        }
    }

    const handleKeyDown = (event, index) => {
        let value = event.target.value.toUpperCase()
        if (event.key === 'Backspace' && value === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus()
            }
        }
    }

    const inputClasses = error.has_error ? "otp-control otp-control-width invalid" : "otp-control otp-control-width"

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className={inputClasses} style={{ display: "flex", flexDirection: "row" }}>
                <input
                    ref={(ref) => (inputRefs.current[0] = ref)}
                    style={{ margin: "0px 2px" }}
                    id="otp1"
                    value={otpInput["otp1"]}
                    onKeyDown={(e) => handleKeyDown(e, 0)}
                    onChange={(e) => handleInputChange(e, 0)}
                    pattern="[0-9]*"
                    inputMode="numeric"
                />
                <input
                    ref={(ref) => (inputRefs.current[1] = ref)}
                    style={{ margin: "0px 2px" }}
                    id="otp2"
                    value={otpInput["otp2"]}
                    onKeyDown={(e) => handleKeyDown(e, 1)}
                    onChange={(e) => handleInputChange(e, 1)}
                    pattern="[0-9]*"
                    inputMode="numeric"
                />
                <input
                    ref={(ref) => (inputRefs.current[2] = ref)}
                    style={{ margin: "0px 2px" }}
                    id="otp3"
                    value={otpInput["otp3"]}
                    onKeyDown={(e) => handleKeyDown(e, 2)}
                    onChange={(e) => handleInputChange(e, 2)}
                    pattern="[0-9]*"
                    inputMode="numeric"
                />
                <input
                    ref={(ref) => (inputRefs.current[3] = ref)}
                    style={{ margin: "0px 2px" }}
                    id="otp4"
                    value={otpInput["otp4"]}
                    onKeyDown={(e) => handleKeyDown(e, 3)}
                    onChange={(e) => handleInputChange(e, 3)}
                    pattern="[0-9]*"
                    inputMode="numeric"
                />
                <input
                    ref={(ref) => (inputRefs.current[4] = ref)}
                    style={{ margin: "0px 2px" }}
                    id="otp5"
                    value={otpInput["otp5"]}
                    onKeyDown={(e) => handleKeyDown(e, 4)}
                    onChange={(e) => handleInputChange(e, 4)}
                    pattern="[0-9]*"
                    inputMode="numeric"
                />
                <input
                    ref={(ref) => (inputRefs.current[5] = ref)}
                    style={{ margin: "0px 2px" }}
                    id="otp6"
                    value={otpInput["otp6"]}
                    onKeyDown={(e) => handleKeyDown(e, 5)}
                    onChange={(e) => handleInputChange(e, 5)}
                    pattern="[0-9]*"
                    inputMode="numeric"
                />
            </div>
        </div>
    )
}

