import { styled } from '../../modules';

export const Image = styled.img`
  width: 100%;
  max-height: 60px;
  margin: 5px 0;
  object-fit: contain;

  @media(max-width: 400px) {
    & {
      max-height: 40px;
    }
  }

  @media(max-width: 360px) {
    & {
      max-height: 30px;
    }
  }
`;