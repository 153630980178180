import { styled } from '../../modules';

type TextProps = {
  $clientThemeSettings: ClientThemeSettings;
};

export const Text = styled.p<TextProps>`
  color: ${(props) => Object.keys(props?.$clientThemeSettings).length > 0 ? props.$clientThemeSettings.font_color : props.theme.colors.colorText};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
`;