import React, { FC } from 'react';
import { SentimentDissatisfied } from '@mui/icons-material';
import { Wallpaper } from '../../components/Wallpaper';
import { Footer } from '../../components/Footer';
import { Logo, Button } from '../../components';

type Props = {
  resetErrorBoundary(): void;
  clientThemeSettings: ClientThemeSettings;
};

const CustomError: FC<Props> = ({ clientThemeSettings, resetErrorBoundary }) => (
  <Wallpaper
    styles={{backgroundColor: clientThemeSettings.background_color}}
  >
    <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
        <Logo imageSource={clientThemeSettings.logo_url} />

        <SentimentDissatisfied style={{ color: '#4d4d4d80', marginTop: '20px', fontSize: '80px' }} />
        <p className="description-level4 gray no-border-container-fullwidth" style={{ textAlign: "justify", margin: '15px 0 5px' }}>
            Ops, algo aconteceu e não conseguimos te redirecionar para a próxima etapa.
        </p>

        <p className="description-level4 gray no-border-container-fullwidth" style={{ textAlign: "justify", margin: '0 0 15px' }}>
          Por favor, clique em continuar para prosseguir com o fluxo.
        </p>

        <Button
          onClick={() => resetErrorBoundary()}
          clientThemeSettings={clientThemeSettings}
        >
          Continuar
        </Button>
        
        <Footer />
    </div>
  </Wallpaper>
);

export default CustomError;
