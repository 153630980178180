import "../assets/styles/index.css";
import QILogo from '../assets/images/qi_tech.png'

export const Footer = (props) => {

    let showLink = false
    if (props.showLink) {
        showLink = true
    }

    if (showLink) {
        return (
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", margin:"20px auto 0px"}}>
                <a
                    className="description-level6 gray"
                    href="https://www.qitech.com.br"
                    target="qitech"
                    style={{display:"flex", flexDirection:"column", alignItems:"center"}}
                >
                    <img
                        style={{ height: '20px', margin: "2px"}}
                        src={QILogo}
                        alt="QI Logo"
                    />
                    <p className="description-level6 gray" style={{ textAlign:"center", width: "60px", margin: "0px" }}>
                        Powered by QI Tech
                    </p>
                    <p className="description-level6 gray" style={{ textAlign:"center", width: "200px", margin: "0px", textDecoration: "underline"}}>
                        https://www.qitech.com.br
                    </p>
                </a>
            </div>
        )
    } else {
        return (
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", margin:"20px auto 0px"}}>
                <img
                    style={{ height: '20px', margin: "2px"}}
                    src={QILogo}
                    alt="QI Logo"
                />
                <p className="description-level6 gray" style={{ textAlign:"center", width: "60px", margin: "0px" }}>
                    Powered by QI Tech
                </p>
            </div>
        )
    }
}