import React, { FC } from 'react';
import { Image } from './styles';

type Props = {
  imageSource: string;
}

const Logo: FC<Props> = ({
  imageSource = '',
}) => (
  <Image
    src={imageSource}
    alt="Logo Cliente"
  />
);

export default Logo;