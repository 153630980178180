import React, { useEffect, useState, useCallback } from "react"
import { Skeleton } from "@mui/material"
import { Refresh, Download } from "@mui/icons-material"
import { Wallpaper } from '../../components/Wallpaper'
import axios from "axios"
import { ErrorPage } from "../ErrorPage"
import {
    PdfContainer,
    Button,
    ButtonInternalContainer,
    ButtonRefresh,
    HeaderText,
    Header,
    HeaderLogo,
    HeaderLeftSide,
    CheckBoxChecked,
    CheckBoxEmpty,
    SkeletonContainer,
    Wrapper,
    ButtonExternalContainer,
    ButtonDescription
} from './styles'
import {
    isMobile
} from '../../utils/utils'
import { handleDeviceScanRequestHelper } from "src/utils/helpers/handleDeviceScan"
import { RetryDeviceScanDialog } from "../Login/RetryDeviceScanDialog"

export const DocumentsInbox = (props) => {
    const { clientThemeSettings, signData, setSignerStep } = props
    const [loading, setLoading] = useState(false)
    const initialError = {
        "has_error": false,
        "status": 0
    }
    const [error, setError] = useState(initialError)
    const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0)
    const [pdfSrc, setPdfSrc] = useState('')
    const [hideIframe, setHideIframe] = useState(true)
    const [disablePrimaryButton, setDisabledPrimaryButton] = useState(true)
    const [wasRead, setWasRead] = useState(signData.documents.map(() => ({
        wasRead: false,
    })))

    const [openRetryDeviceScanDialog, setOpenRetryDeviceScanDialog] = useState(false)

    const reloadPage = () => {
        setDisabledPrimaryButton(true)
        setHideIframe(true)

        setTimeout(() => setHideIframe(false), 500)
    }

    const downloadPDF = (pdfUrl, documentName) => {
        const link = document.createElement('a')
        link.href = pdfUrl
        link.download = documentName + '.pdf'
        link.target = '_blank'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const onSubmit = async () => {
        const data = {
            "event_date": new Date().toISOString(),
            "step": "document_display"
        }
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }

        await axios.patch('/signer', data, config)
            .then(response => {
                if (response.data.next_step === 'device_scan') {
                    handleDeviceScanRequestHelper("Documents Inbox", "1", signData, setSignerStep, setError, setLoading, null, null, setOpenRetryDeviceScanDialog)
                } else {
                    setSignerStep(response.data.next_step)
                }
            })
            .catch((error) => {
                if ([401, 403].includes((error.response || {}).status)) {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                } else {
                    setError({
                        "has_error": true,
                        "status": 500
                    })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setPdfLink = useCallback((currentDocumentIndex) => {
        if (isMobile()) {
            setPdfSrc(`https://docs.google.com/viewer?url=${encodeURIComponent(signData.documents[currentDocumentIndex].url)}&embedded=true`)
            return
        }

        setPdfSrc(signData.documents[currentDocumentIndex].url)
    }, [signData.documents])

    const setReadDocuments = async (documentKey) => {
        const updatedWasRead = wasRead.map((documentReadState, index) =>
            index === documentKey ? { wasRead: true } : documentReadState
        )
        setWasRead(updatedWasRead)

        if (documentKey + 1 === signData.documents.length) {
            await onSubmit()
            return
        }

        setPdfLink(documentKey + 1)
        setCurrentDocumentIndex(documentKey + 1)
        reloadPage()
    }

    const enableSignButton = () => setDisabledPrimaryButton(false)

    useEffect(() => {
        setPdfLink(currentDocumentIndex)
        setDisabledPrimaryButton(true)
        setHideIframe(true)

        setTimeout(() => setHideIframe(false), 500)
    }, [currentDocumentIndex, setPdfLink])

    return (
        <Wrapper>
            <PdfContainer>
                <Header>
                    <HeaderLogo
                        src={clientThemeSettings.logo_url}
                        alt="Logo Cliente"
                    />
                    <HeaderLeftSide>
                        <HeaderText>
                            Documentos:
                        </HeaderText>
                        {wasRead.map((documentReadState, index) => (
                            !documentReadState.wasRead ?
                                <CheckBoxEmpty key={index} $color={'#d7d7d7'} /> :
                                <CheckBoxChecked key={index} $color={clientThemeSettings.background_color} />
                        ))}
                    </HeaderLeftSide>
                </Header>
                {!hideIframe ? (
                    <iframe
                        key={currentDocumentIndex}
                        title={signData.documents[currentDocumentIndex].name}
                        src={pdfSrc}
                        onLoad={() => {
                            enableSignButton()
                        }}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                    />
                ) : (
                    <SkeletonContainer>
                        {new Array(20).fill(0).map((_, index) => (
                            <Skeleton key={index} variant='text' sx={{ fontSize: '1rem', width: `${index % 2 === 0 ? '80%' : '100%'}` }} />
                        ))}
                    </SkeletonContainer>
                )}
                <ButtonExternalContainer>
                    <ButtonDescription>
                        {signData.documents[currentDocumentIndex].name}
                    </ButtonDescription>
                    <ButtonInternalContainer>
                        <ButtonRefresh
                            onClick={reloadPage}
                            clientThemeSettings={clientThemeSettings}
                            type={'primaryGhost'}
                            disabled={loading}
                        >
                            <Refresh />
                        </ButtonRefresh>

                        <ButtonRefresh
                            onClick={() => (downloadPDF(signData.documents[currentDocumentIndex].url, signData.documents[currentDocumentIndex].name))}
                            clientThemeSettings={clientThemeSettings}
                            type={'primaryGhost'}
                        >
                            <Download />
                        </ButtonRefresh>

                        <Button
                            onClick={() => {
                                setReadDocuments(currentDocumentIndex)
                            }}
                            clientThemeSettings={clientThemeSettings}
                            loading={loading}
                            disabled={disablePrimaryButton}
                        >
                            ASSINAR
                        </Button>
                    </ButtonInternalContainer>
                </ButtonExternalContainer>
            </PdfContainer>
            {error.has_error && (
                <Wallpaper
                    backgroundColor={clientThemeSettings["background_color"]}
                >
                    {error.has_error &&
                        <ErrorPage
                            error={error}
                            redirectToLogin={() => setSignerStep("login")}
                            resetError={() => setError(initialError)}
                            clientThemeSettings={clientThemeSettings}
                        />
                    }
                </Wallpaper>
            )}
            {openRetryDeviceScanDialog &&
            <RetryDeviceScanDialog
                open={openRetryDeviceScanDialog}
                retryDeviceScan={() => handleDeviceScanRequestHelper("Documents Inbox", "1", signData, setSignerStep, setError, setLoading, null, null, setOpenRetryDeviceScanDialog)}
                clientThemeSettings={clientThemeSettings}
            />}
        </Wrapper>
        
    )
}