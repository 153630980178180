import { styled } from '../../modules';
import { Button as ButtonComponent, Typography } from '../../components';
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material"

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
`;

export const PdfContainer = styled.section`
  width: 100%;
  height: 100%;
`;

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: '#fff';
  padding: 10px;
  margin: 0;
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type CheckBoxProps = {
  $color: string;
}

export const CheckBoxEmpty = styled(CheckBoxOutlineBlank)<CheckBoxProps>`
  color: ${props => props.$color};
  font-size: 18px;
`;

export const CheckBoxChecked = styled(CheckBox)<CheckBoxProps>`
  color: ${props => props.$color};
  font-size: 18px;
`

export const HeaderLogo = styled.img`
  max-height: 25px;
  object-fit: contain;
`;

export const HeaderText = styled(Typography)`
  font-size: 13px;
  margin: 0 5px 0 0;
  font-weight: 500;
`;

export const SkeletonContainer = styled.div`
  padding: 10px;
`;

export const ButtonExternalContainer = styled.div`
  width: 100%;
  max-width: 800px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0000001A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1.0rem;
  z-index: 999;
`;

export const ButtonInternalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Button = styled(ButtonComponent)`
  width: 45%;
  max-width: 300px;
  z-index: 999;
`;

export const ButtonRefresh = styled(ButtonComponent)`
  width: 80px;
  min-width: 80px;
  margin-right: 10px;
  z-index: 999;
`;

export const ButtonDescription = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
`;