import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export const PersonalDocumentSlider = ({ documents }) => {

    return (
        <div style={{ display: 'grid'}}>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#000',
                    '--swiper-pagination-color': '#000',
                }}
                lazy={'false'}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
            {documents.map((document, index) => (
                <SwiperSlide key={index}>
                    <img src={document.url} loading="lazy" className="swiper-image" alt={"Documento de Identificação"} style={ {height:"85%"}} />
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
            ))}
            </Swiper>
        </div>
    )
}